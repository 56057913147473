<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title">
						<div><i></i><span>数据列表</span></div>
						<div>
							<el-button size="small" type="primary" @click="download">导出</el-button>
						</div>
					</div>
					<template v-if="list.length > 0">
						<el-table ref="multipleTable" :data="list" class="form-table" border style="width: 100%" @selection-change="handleSelectionChange">
							<el-table-column type="selection" width="40"></el-table-column>
							<el-table-column type="index" label="序号" width="60"></el-table-column>
							<el-table-column prop="city" label="地区" ></el-table-column>
							<el-table-column prop="portString" label="终端" ></el-table-column>
							<el-table-column prop="createTime" label="收集时间" ></el-table-column>
							<el-table-column fixed="right" label="操作" width="250">
								<template slot-scope="scope">
									<el-button type="text" @click="view(scope.row._id)">详细</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
					</template>
					<noData v-else text="暂时还没有数据哦！"></noData>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			fromType:'',
			list:[],
			listParams:{
				pageNo:1,
				pageSize:20,
				formId:''
			},
			count: 0,
			multipleSelection:[],
		}
	},
	created(){
		this.listParams.formId = this.$route.query.id?this.$route.query.id:'';
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		this.getTplList();
	},
	methods: {
		// 获取列表
		getTplList(){
			const _this = this;
			get('/formadmin/formmodel/from_data_list.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.formDataList;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getTplList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getTplList();
		},
		// 导出
		download(){
			const _this = this;
			let ids = [];
			let params = {
					formId:this.listParams.formId,
					dataIds:''
				}
			if(_this.multipleSelection && _this.multipleSelection.length>0){
				_this.multipleSelection.forEach(item=>{
					ids.push(item._id)
				})
				params.dataIds = ids.toString();
				this.$confirm('您确定导出指定表单数据！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					post('/formadmin/export_verify.jhtml',params).then(res => {
						if(res.code == 200){
							window.location.href = '/formadmin/xls_download.jhtml?formId='+ params.formId + '&dataIds=' + params.dataIds
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}).catch(() => {
					return false;
				});
			}else{
				this.$confirm('您确定导出所有表单数据！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					post('/formadmin/export_verify.jhtml',params).then(res => {
						if(res.code == 200){
							window.location.href = '/formadmin/xls_download.jhtml?formId='+ params.formId + '&dataIds=' + params.dataIds
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}).catch(() => {
					return false;
				});
			}
			
			
			
			
		},// 编辑
		view(id){
			const _this = this;
			_this.$router.push({
				path:'/detail',
				query:{
					dataId:id,
					formId:_this.listParams.formId
				}
			})
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		}
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			display: flex;
			justify-content: space-between;
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
	}
	.form-table{
		.img{
			width:200px;
			height: 100px;
		}
		.i-ico{
			width:20px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			background-image: url(../../../assets/images/ico_table.png);
		}
		.operate-btn{
			span{
				vertical-align: middle;
			}
			.i-edit{background-position: 3px 3px;}
			.i-delete{background-position: -87px 2px;}
			&:hover{
				color:var(--theme-color);
				.i-edit{background-position: 3px -27px;}
				.i-delete{background-position: -87px -28px;}
			}
		}
		.operate-btn+.operate-btn{margin-left:15px;}
	} 
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			overflow-y: auto;
			.el-form-item:last-child{
				margin-bottom: 0;
			}
			.avatar-uploader{
				width:100px;
				height: 100px;
				border:1px solid #DCDFE6;
					text-align: center;
				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width:100%;
					height: 100%;
					line-height:100px;
				}
				.avatar {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
		}
	} 
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>